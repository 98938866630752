import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="Despre noi" items={items}>
      <SEO title="Despre EBE" keywords={[`istoric`, `fundația ebe`]} />
      <P>
        Timp de 10 de ani de zile, începând cu anul 1979, în cadrul unor
        programe clandestine organizate de către BEE-I (Biblical Education by
        Extension-International, actualul Entrust) în timpul unui regim comunist
        opresiv față de biserică, mulți lideri, inclusiv actualii lideri de la
        EBE, au fost echipați în grupuri mici de studiu pentru a sluji biserica
        și nevoile ei.
      </P>
      <P>
        Sub directa asistență și sprijinul organizației Entrust și a
        misionarilor Entrust aflați în Romania, s-au stabilit apoi după
        revoluția din Decembrie 1989, bazele apariției organizației indigene de
        educație biblică prin exensie – EBE. Având programe pilot pentru
        deschiderea și dezvoltarea de CPBL, cu studenți dintre care unii lideri
        de biserici, iar alții, membri cu potențial în adunările lor locale, în
        diferite zone geografice din țară, EBE a depus eforturi considerabile în
        dezvoltarea și folosirea programei de pregătire a liderilor slujitori.
        În colaborare cu Entrust iar mai târziu și cu CRM sau Elmbrook Church
        din SUA, s-au făcut eforturi și investiții financiare pentru traducere,
        contextualizarea materialelor, editarea și implementarea cursurilor
        precum și a programelor de echipare, implementarea viziunii și a
        strategiilor care aduc beneficii diferitelor mișcări evanghelice. EBE a
        încercat să dezvolte și să mențină parteneriate solide cu toate
        denominațiile evanghelice și cu biserici locale mai mari din țară. S-au
        deschis tot mai multe CPBL prin lucrarea cărora bisericile locale
        respective au echipat oameni și au început apoi să promoveze astfel,
        slujitori în zona lor geografică.
      </P>
      <P>
        Fiecare denominație evanghelică din Romania are acum propriul Sistem de
        echipare și există la fiecare Sistem o Echipă de Conducere, sub
        coordonarea directă a Coordonatorului Național de la sistemul respectiv.
        Astfel avem sisteme de pregătire la Cultul Crestin Penticostal, Cultul
        Creștin Baptist, Cultul Creștin după Evanghelie, Mișcarea Evanghelică a
        Bisericilor Autonome și există deasemenea un Sistem pentru Lucrarea cu
        Femeile. Se află în dezvoltare și o lucrare de echipare în cadrul
        mișcării Oastea Domnului.
      </P>
      <P>
        În anul 2003, mai mult de 2000 de studenți erau înregistrați în programe
        de pregătire în diferite CPBL. Apoi, în anul 2005, erau 5500 de
        studenți, bărbați și femei, în pregătiri de niveluri diferite în CPBL
        din țară. La ora actuală nu se mai ține strict o asemenea evidență,
        Centrele de Pregătire aparținând bisericilor și nu lui EBE iar programa
        de echipare din multe CPBL are și alte cursuri pe lângă cursurile EBE.
        Viziunea a fost captată de foarte mulți lideri, materialele de pregătire
        se pun la dispoziție prin EBE tuturor celor interesați și fiecare Sistem
        încercearcă în colaborare cu denominația respectivă să răspundă nevoilor
        specifice.
      </P>
    </PageWithLeftMenu>
  )
}
